@import "../assets/scss/bootstrap/variables";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";

.nav-item {
    &.active {
        background-color: $primary;
        color: $white;
    }
}

.tab-content {
    padding: {
        bottom: $grid-gutter-width / 2;
        top: $grid-gutter-width / 2;
    }
}

$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: $success !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: $primary !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: $border-radius;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.7rem !default;
$datepicker__font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$datepicker__item-size: 1.2rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.3rem !default;
$datepicker__triangle-size: 0 !default;

@import "../../node_modules/react-datepicker/src/stylesheets/datepicker";

.react-datepicker-wrapper {
    display: block;
}

.react-datepicker__input-container {
    display: block;
}

.input-group-prepend {
    cursor: pointer;
}
.invalid-feedback {
    display: block;
}
