@import "../bootstrap/variables";

.assessment-wrapper {
    padding-top: 15px;

    .assessment {
        box-shadow: 0 3px 20px $gray-700;
        margin-bottom: 20px;

        @media (min-width: 768px) {
            margin-bottom: 0;
        }

        .inner {
            @media (min-width: 768px) {
                height: 75vh;
                min-height: 400px;
                overflow-y: scroll;
                margin-bottom: 0;
            }
        }

        .label-equal {
            height: 44px;
        }

        .btn {
            max-width: 40vw;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .nav-tabs {
            .nav-item {
                user-select: none;
                .nav-link {
                    padding: 8px 8px;
                }
            }
        }

        label.required {
            &:after {
                content: "*";
                margin-left: 3px;
                color: $danger;
            }
        }

        label.question {
            font-weight: bold;
            font-size: 1rem;

            @media (min-width: 768px) {
                font-size: 1.2rem;
            }
        }

        .tab-content {
            overflow-y: scroll;
            margin-top: 20px;
            @media (min-width: 992px) {
                height: 480px;
            }
            @media (min-width: 1200px) {
                height: 620px;
            }

            .form-group {
                margin-bottom: 30px;

                &.vertical-checkbox {
                    .vertical-list {
                        label {
                            padding-left: 20px;
                            position: relative;
                            input {
                                position: absolute;
                                left: 0px;
                                top: 50%;
                                transform: translate(0, -50%);
                                margin-right: 5px;
                            }
                        }
                    }
                }

                &.yes-no {
                    input {
                        margin-right: 10px;
                        margin-top: 12px;
                    }
                }

                &.matrix {
                    margin-bottom: 10px;

                    thead {
                        th {
                            text-align: center;
                        }
                    }

                    tbody {
                        td {
                            width: 16.5%;
                            text-align: center;

                            &:first-child {
                                text-align: left;
                            }

                            label {
                                margin-bottom: 0px;
                                text-align: center;
                                padding: 8px 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
