@import "node_modules/bootstrap/scss/nav";

.nav.nav-tabs {
    .nav-item {
        .nav-link {
            border-color: $primary;
        }

        &:hover {
            background-color: lighten($secondary, 50%);
            cursor: pointer;
        }
    }
}
