@import "../bootstrap/variables";

.loading-spinner {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 90px auto;
    position: relative;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-left-color: $white;
    transform: translateZ(0);
    animation: loading-spinner 0.5s infinite linear;
}

.loading-wrapper {
    text-align: center;
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
