@import "../../../../assets/scss/bootstrap/variables";
// DEFAULT COLOR VARIABLES

$ball-color: #fff;
$active-color: $success;
$inactive-color: $danger;

// DEFAULT SIZING VARIABLES

$switch-size: 28px;
$ball-spacing: 2px;
$stretch-factor: 1.625;

// DEFAULT CLASS VARIABLE

$switch-class: "switch-toggle";

@mixin switch($size: $switch-size, $spacing: $ball-spacing, $stretch: $stretch-factor, $color: $active-color, $class: $switch-class) {
    // SELECTOR VARIABLES

    $self: "." + $class;
    $on: #{$self}--on;
    $off: #{$self}--off;

    // SWITCH VARIABLES

    $active-color: $color;
    $switch-size: $size;
    $ball-spacing: $spacing;
    $stretch-factor: $stretch;
    $ball-size: $switch-size - $ball-spacing - 2;
    $ball-slide-size: ($switch-size - ($stretch-factor + 4 + $ball-spacing));

    cursor: pointer !important;
    display: inline-block;

    // SWITCH STYLES

    height: $switch-size;
    position: relative !important;
    user-select: none !important;
    width: $switch-size * $stretch-factor;

    &#{$on},
    &#{$off} {
        &::before,
        &::after {
            content: "";
            left: 0;
            position: absolute !important;
        }

        &::before {
            border-radius: $switch-size / 2;
            height: inherit;
            transition: background 0.4s 0.3s ease-out;
            width: inherit;
            will-change: background;
        }

        &::after {
            background: $ball-color !important;
            border-radius: $ball-size / 2;
            height: $ball-size;
            top: $ball-spacing;
            transition: transform 0.4s ease-out;
            width: $ball-size;
            will-change: transform;
        }
    }

    &#{$on} {
        &::before {
            background: $active-color !important;
        }

        &::after {
            transform: translateX($ball-slide-size);
        }
    }

    &#{$off} {
        &::before {
            background: $inactive-color !important;
        }

        &::after {
            transform: translateX($ball-spacing);
        }
    }
}

@function get-switch-class($selector) {
    // First parse the selector using `selector-parse`
    // Extract the first selector in the first list using `nth` twice
    // Extract the first simple selector using `simple-selectors` and `nth`
    // Extract the class name using `str-slice`

    @return str-slice(nth(simple-selectors(nth(nth(selector-parse($selector), 1), 1)), 1), 2);
}

.switch {
    $self: &;
    $toggle: #{$self}-toggle;
    $class: get-switch-class($toggle);

    display: inline-block;

    margin: {
        left: 1.5rem;
        right: 1.5rem;
        top: calc(#{$input-padding-y} + #{$input-border-width});
    }

    // default theme
    &#{$self}--default > #{$toggle} {
        // Always pass the $class to the mixin
        @include switch($class: $class);
    }

    // graphite-small theme
    &#{$self}--graphite-small > #{$toggle} {
        // A smaller switch with a `gray` active color
        // Always pass the $class to the mixin
        @include switch($color: gray, $size: 20px, $class: $class);
    }
}
