@import "../bootstrap/variables";
nav.navbar {
    .navbar-brand {
        img {
            max-height: 50px;
        }
    }
    a {
        cursor: pointer;
    }
    ul a {
        text-transform: uppercase;
        margin-left: 35px;
    }
}
